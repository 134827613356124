import React, {useEffect, useState} from 'react';
import groupApi from "../../../api/group.api";
import './MemberInviteGroup.sass';
import { useSelector } from "react-redux";
import { Button, Tag, Space, Form, Row, Col, Modal, Select, message } from 'antd';

const MemberInviteGroup = React.memo((props) => {
    const {showModal, setShowModal} = props;
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const room = useSelector(state => state.io.room);
    const [form] = Form.useForm();

    useEffect(() => {
        getUsers()
    }, []);

    const getUsers = async (search = '') => {
        if(!room){
            return;
        }

        setLoading(true);

        try {
            // Giả sử API trả về dữ liệu như thế này
            let res = await groupApi.getUsers({search, source: 'zalo'});
            const response = res.data;

            const people = room.people;
            const peopleIds = people.map(e => e._id)

            let result = [];

            for (let i = 0; i < response.length; i++) {
                const user = response[i];
                if(!peopleIds.includes(user._id)){
                    result.push(user);
                }
            }

            setUsers(result);
        } catch (error) {
            console.log('Có lỗi xảy ra!');
        } finally {
            setLoading(false);
        }
    };

    const getOptionUsers = () => {
        let options = [];
        if(users && users.length){
            options = users.map(e => (
                {label: e.firstName, value: e.userSocialId, id: e._id, picture: e.picture}
            ));
        }

        return options;
    }

    const customTagRender = (props) => {
        const { label, value, closable, onClose } = props;

        let user = {};

        if(users && users.length){
            let findUser = users.find(e => e.userSocialId === value);
            user = findUser
        }

        return (
            <Tag
                className="tag-default-select"
                closable={closable}
                onClose={onClose}
                style={{ display: 'flex', alignItems: 'center', marginRight: 3, fontWeight: '600', border: 'none', padding: '2px 5px', marginTop: '2px', marginBottom: '2px' }}
            >
                <Picture user={user}/>{label}
            </Tag>
        );
    };

    const Picture = ({user}) => {
        let name = user.label || user.firstName
        if (user && user.picture?.location)
            return <img className="avatar-user" src={user.picture?.location} alt="avatar" />;
        else
            return <div className="avatar-user-text">{`${name.substr(0,1)}`}</div>;
    };

    const onSubmit = async (values) => {
        const payload = {
            ...values,
            group_id: room.oa_group_id,
            channel_id: room.channel._id
        };

        const res = await groupApi.inviteMember(payload);

        const result = res.data;

        if(!result.error){
            message.success('Mời thành viên vào nhóm OA thành công!');
            setShowModal(false)
            return;
        }

        message.error(result.message);
    }

    return (
        <Modal
            open={showModal}
            id="modal-add-group"
            title="Mời thành viên vào nhóm"
            onCancel={() => setShowModal(false)}
            width={700}
            footer={null}
            confirmLoading={loading}
            centered>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit} // Khi submit form
                    >
                    <Row style={{ marginTop: '30px' }}>
                        <Col span={6}>Chọn người dùng</Col>
                        <Col span={18}>
                            <Form.Item name="member_user_ids" rules={[{required: true, message: 'Bạn chưa chọn người dùng tham gia nhóm'}]}>
                                {users && (
                                    <Select
                                        mode="multiple"
                                        className="select-users-add-group"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Chọn người dùng"
                                        options={getOptionUsers()}
                                        tagRender={customTagRender}
                                        optionRender={(option) => (
                                            <Space>
                                                <Picture user={option.data}/> {option.data.label}
                                            </Space>
                                        )}
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className='text-right'>
                        <Button type="primary" htmlType="submit">Mời</Button>
                    </Form.Item>
                </Form>
        </Modal>
    );
});

export default MemberInviteGroup;
