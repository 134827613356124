import React, {useState} from 'react';
import "./BottomBarNotSend.sass";
import 'emoji-mart/css/emoji-mart.css';
import {useSelector} from "react-redux";
import { Button } from 'antd';

const BottomBarPreventSend = () => {
    const room = useSelector(state => state.io.room);

    return (
        <div className={`bottom-bar-not-send action-event`}></div>
    );
}

export default BottomBarPreventSend;
