import React, {useRef, useState, useEffect, useCallback, useContext} from 'react';
import "./BottomBar.sass";
import "./BottomBar.css";
import {
    FiImage,
    FiSmile,
    FiSend,
    FiLayers,
    FiMic,
    FiPaperclip
} from "react-icons/fi";
import {useGlobal} from "reactn";
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import message from "../../../actions/message";
import messageClient from "../../../actions/messageClient";
import uploadImage from "../../../actions/uploadImage";
import uploadFile from "../../../actions/uploadFile";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import {AiOutlineCloseCircle, FaUsers, IoIosCloseCircle, MdFormatQuote} from "react-icons/all";
import MasterConstant from "../../../constants/MasterConstant";
import updateMessage from "../../../actions/updateMessage";
import { MentionsInput, Mention } from 'react-mentions'
import defaultStyleTagUser from './defaultStyleTagUser'
import defaultMentionStyle from './defaultMentionStyle'
import BoxMessageTemplate from '../components/BoxMessageTemplate'
import Config from '../../../config';
import {merge} from "../../../lib/utils";
import store from "../../../store";
import VoiceModal from "./VoiceModal"
import { AppContext } from '../../../context/AppContext';

const BottomBar = () => {
    const imageInput = useRef(null);
    const fileInput = useRef(null);

    const [openVoice, setOpenVoice] = useState(false);

    const ref = useGlobal('ref')[0];
    const room = useSelector(state => state.io.room);
    const messages = useSelector(state => state.io.messages) || [];
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const chatMode = useGlobal('chatMode')[0];

    const { showAlertAction, i18n } = useContext(AppContext);

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];

    ///
    const currentRoom = store.getState().io.room;
    const [currentMessage, setCurrentMessage] = useGlobal('currentMessage');
    const [isPicker, showPicker] = useGlobal('isPicker');
    const livechatColor = useGlobal('livechatColor')[0];

    const [pictureRefs, addPictureRef] = useState([]);
    const [messageModeAction, setMessageModeAction] = useGlobal('messageModeAction');
    const [messageGetData, setMessageGetData] = useGlobal('messageGetData');
    const [reply, setReply] = useGlobal('reply');
    const [messageReply, setMessageReply] = useGlobal('messageReply');
    const [quotaMessage, setQuotaMessage] = useState(null);
    const [userTag, setUserTag] = useState();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setQuotaMessage(null);
        handlePreventSendMessage();
    }, [currentRoom]);

    useEffect(() => {
        if(room && room.isGroup){
            const admin = user;

            let userTag = [];

            room.people.forEach(user => {
                if(admin.id !== user._id){
                    let dataUser = {
                        id : user.userSocialId,
                        display : user.firstName + ' ' + user.lastName,
                        lastName : user.lastName,
                        firstName : user.firstName,
                    };
    
                    if(user.picture){
                        dataUser.picture = user.picture.shieldedID;
                    }
    
                    userTag.push(dataUser);
    
                    return userTag;
                }
            })

            setUserTag(userTag);
        }

        setValue(currentMessage);

    }, [room, currentMessage])

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const appendMessageTemp = (content) => {
        let messageObj = JSON.parse(JSON.stringify(messages))
        let lastMessage = messageObj[messageObj.length - 1];

        const currentDate = new Date();
        const isoDateString = currentDate.toISOString();

        //Nếu là tin nhắn đầu tiên
        if(!lastMessage){
            lastMessage = {
                type: 'text',
                content,
                error_message: '',
                author: {
                    id: user.id,
                    picture: '',
                    firstName: user.firstName,
                    lastName: user.lastName,
                },
                date: isoDateString,
                _id: '123'
            };

            if(chatMode === 'client' && room){
                lastMessage.author = room.owner;
            }
        }
        else{
            lastMessage.type = 'text';
            lastMessage.content = content;
            lastMessage.error_message = '';
            lastMessage.author._id = user.id;
            lastMessage.author.picture = '';
            lastMessage.author.firstName = user.firstName;
            lastMessage.author.lastName = user.lastName;
            lastMessage.session_status = 'middle';
            lastMessage.date = isoDateString;
            lastMessage._id = '123';
        }

        if(chatMode === 'client'){
            lastMessage.staff = null;
            lastMessage.staff_id = null;
        }

        store.dispatch({type: Actions.MESSAGE, message: lastMessage});
    }

    const sendMessage = async () => {
        if (value.length === 0) return;

        let finalText = value.trim();
        let resMess;

        setValue('');
        setCurrentMessage('');
        if(messageModeAction !== 'edit'){

            //Append tin nhắn vào cuối đoạn chat (khi tin nhắn được gửi thành công sẽ cập nhật lại)
            appendMessageTemp(finalText);

            let messageData = { roomID: room._id, authorID: user.id, content: finalText, type: 'text', action : 'message'};
            if(reply){
                messageData.replies = messageReply;
                messageData.action = 'reply';
            }

            if(chatMode === 'client'){
                resMess = await messageClient(messageData);
            }
            else{
                resMess = await message(messageData);
            }
            
            resMess = resMess?.data;

            if(!resMess){
                return;
            }

            let newMessage = resMess.data.message;
            let quotaZalo = resMess.data.quota;

            setReply(false);

            showPicker(false);
            setMessageModeAction('new');
            setMessageGetData([]);
            setQuotaMessage(quotaZalo);

            if(resMess.error){
                newMessage = resMess.data.message;
                newMessage.error_message = resMess.message;
            }

            store.dispatch({type: Actions.SET_WAIT_MESSAGE, message: newMessage});

            if(chatMode !== 'client'){
                reloadRooms();
            }

        } else {
            let messageData = {
                type: 'edit', roomID: room._id, messageId: messageGetData._id, data : finalText, action: 'edit'
            };
            if(reply){
                messageData.replies = messageReply;
            }

            updateMessage(messageData);

            setReply(false);
            showPicker(false);
            setMessageModeAction('new');
            setMessageGetData([]);
        }
    };

    

    const getMessageQuotaText = () => {
        let quota = quotaMessage;
        if(!quota){
            return
        }

        let html = '';
        let classColor = '';

        if(quota.type === 'reply'){
            html = `Bạn còn ${quota.remain}/${quota.total} tin nhắn chủ động miễn phí`;
            classColor = 'quota-message';
        }
        else if(quota.type === 'OA Tier'){
            html = "Tin tiếp theo bạn gửi đi là tin tư vấn miễn phí";
            classColor = 'quota-message';
        }
        else{
            html = "Tin tiếp theo bạn gửi đi là tin tính phí (55đ/tin)";
            classColor = 'quota-message warning';
        }

        return (<div className={classColor}>{html}</div>);
    };

    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            if (event.type === "keydown" && !event.shiftKey) {
                sendMessage();
                event.preventDefault();
            }
        }
    };

    const sendImages = async images => {
        let tmpRefs = [];

        //Append tin nhắn vào cuối đoạn chat (khi tin nhắn được gửi thành công sẽ cập nhật lại)
        appendMessageTemp(`${i18n.t('sending_photo')}...`);
        //

        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            tmpRefs.push(ref + i);

            const res = await uploadImage(image, ref + i);

            if(res.data.error){
                showAlertAction('warning', res.data.message);
                return;
            }

            let resMess;
            let messageData = {roomID: room._id, authorID: user.id, content: res.data.image.shieldedID, type: 'image', imageID: res.data.image._id};
            if(chatMode === 'client'){
                resMess = await messageClient(messageData);
            }
            else{
                resMess = await message(messageData);
            }

            resMess = resMess?.data;

            let newMessage = resMess?.data?.message;
            
            if(resMess.error){
                newMessage = resMess.data.message;
                newMessage.error_message = resMess.message;
            }

            if(i === 0){
                store.dispatch({type: Actions.SET_WAIT_MESSAGE, message: newMessage});
            }
            else{
                store.dispatch({type: Actions.MESSAGE, message: newMessage});
            }
        }
        addPictureRef([...pictureRefs, ...tmpRefs]);
        showPicker(false);
        reloadRooms();
    };

    const sendFiles = async files => {
        for (let i = 0; i < files.length; i++) {
            if ((files[i].size / (1024 * 1024)) > 10) return alert('File exceeds 10MB limit!');
        }

        //Append tin nhắn vào cuối đoạn chat (khi tin nhắn được gửi thành công sẽ cập nhật lại)
        appendMessageTemp(`${i18n.t('sending_file')}...`);
        //

        let tmpRefs = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            tmpRefs.push(ref + i);
            const res = await uploadFile(file, ref + i);

            if(res.data.error){
                showAlertAction('warning', res.data.message);
                return;
            }

            let resMess;
            let messageData = {roomID: room._id, authorID: user.id, content: res.data.file.shieldedID, type: 'file', fileID: res.data.file._id}

            if(chatMode === 'client'){
                resMess = await messageClient(messageData);
            }
            else{
                resMess = await message(messageData);
            }

            resMess = resMess?.data;

            let newMessage = resMess?.data?.message;
            
            if(resMess.error){
                newMessage = resMess.data.message;
                newMessage.error_message = resMess.message;
            }
            
            if(i === 0){
                store.dispatch({type: Actions.SET_WAIT_MESSAGE, message: newMessage});
            }
            else{
                store.dispatch({type: Actions.MESSAGE, message: newMessage});
            }
        }
        addPictureRef([...pictureRefs, ...tmpRefs]);
        showPicker(false);
        reloadRooms();
    };

    const closeReply = () => {
        setReply(false);
        setMessageReply(false);
        if(messageModeAction === 'reply') setMessageModeAction('new');
    }

    const ContentReply = () => {
        if(messageModeAction === 'edit' && messageGetData.replies){
            return MasterConstant.contentReply(messageGetData.replies);
        } else {
            return MasterConstant.contentReply(messageGetData);
        }
    };

    const ReplyMessage = () => {
        if(messageModeAction === 'edit' && !messageGetData.replies) {
            return (<div></div>);
        }
        else {
            return (
                <div className="answer_message_block">
                    <div className="block-content-message">
                        <div className="block-content-message-child position-relative">
                            <span><MdFormatQuote/> Replies to <strong>{messageGetData.author.firstName} {messageGetData.author.lastName}</strong></span>
                            <span><ContentReply/></span>
                            <span className="close_comma" onClick={closeReply}>
                            <IoIosCloseCircle/>
                        </span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const cancelEdit = () => {
        setMessageModeAction('new');
        setMessageGetData([]);
        setCurrentMessage('');
        setReply(false);
    }

    const onChange = useCallback((_, newValue) => setValue(newValue), [setValue])
    const onAdd = useCallback((...args) => console.log(...args), [])

    let style = merge({}, defaultStyleTagUser, {
        input: {
            overflow: 'auto',
            height: 70,
        },
        highlighter: {
            boxSizing: 'border-box',
            overflow: 'hidden',
            height: 70,
        },
    })

    const handlePreventSendMessage = () => {
        let isPreventMessageClass = false;
        if(currentRoom){
            let userChat = currentRoom.owner;

            if(userChat && userChat.isBlocked && !isPreventMessageClass){
                isPreventMessageClass = true;
            }
        } 

        if(isPreventMessageClass){
            document.getElementsByClassName('send-message-box')[0].classList.add('prevent-events');
        }else{
            document.getElementsByClassName('send-message-box')[0].classList.remove('prevent-events');
        }
    }

    const Picture = ({user}) => {
        if(user.id !== 'all'){
            if (user.picture)
                return <img className="img" src={`${Config.url || ''}/api/images/${user.picture}/256/${brand}`} alt="chat" />;
            else
                return <div className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
        } else {
            return <div className="img"><FaUsers/></div>;

        }

    };

    const hanldeShowMessageTemplate = () => {
        setShowDialog(current => !current);
    }

    return (
        <div className={`send-message-box action-event`}>
            <div className="bottom-bar-tool">
                <div className="picker" hidden={!isPicker}>
                    <Picker
                        onSelect={emoji => setValue(value + emoji.native)}
                        darkMode={false}
                        title="Emoji"
                        native={true}
                        set="facebook"
                    />
                </div>
                <div className="button smile" onClick={() => showPicker(!isPicker)}><FiSmile/></div>

                {messageModeAction === 'new' && (
                    <input
                        className="file-input"
                        type="file"
                        ref={imageInput}
                        accept="image/*"
                        multiple={true}
                        onChange={e => sendImages(e.target.files)}
                    />
                )}

                {messageModeAction === 'new' && (
                    <div className="button image-attach" onClick={() => imageInput && imageInput.current && imageInput.current.click()}>
                        <FiImage/>
                    </div>
                )}

                {messageModeAction === 'new' && (
                    <input
                        className="file-input"
                        type="file"
                        accept={room && room.source === 'client' ? "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx, .xls, .csv" : "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                        ref={fileInput}
                        multiple={true}
                        onChange={e => sendFiles(e.target.files)}
                    />
                )}

                {messageModeAction === 'new' && (
                    <div className="button attach" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                        <FiPaperclip/>
                    </div>
                )}

                {chatMode !== 'client' && room && room.source === 'facebook' && (
                    <div className="button attach" onClick={() => hanldeShowMessageTemplate()}>
                        <FiLayers/>
                    </div>
                )}

                {chatMode === 'client' && (
                    <div className="button attach" onClick={() => setOpenVoice(true)}>
                        <FiMic/>
                    </div>
                )}

                {openVoice && <VoiceModal openVoice={openVoice} setOpenVoice={setOpenVoice} />}

                {showDialog && <BoxMessageTemplate setShowDialog={setShowDialog}/>}

                {/* {getMessageQuotaText()} */}

            </div>
            {reply && (<ReplyMessage/>)}
            <div className="bottom-bar-conversation uk-flex uk-flex-middle">
                <MentionsInput
                    className="message-input"
                    onKeyPress={handleKeyPress}
                    onKeyDown={handleKeyPress}
                    value={value}
                    data-emoji-input="unicode"
                    // inputRef={c => (setTextarea(c))}
                    onChange={onChange}
                    style={style}
                    placeholder={`${i18n.t('enter_message')}...`}
                    a11ySuggestionsListLabel={"Suggested mentions"}
                    rows={1}
                >
                    <Mention
                        markup="@__display__-__id__@"
                        displayTransform={(id, display) => `@${display}`}
                        trigger="@"
                        data={userTag}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => {
                            return (<div><Picture user={suggestion} /><div className="user">{highlightedDisplay}</div></div>);
                        }}
                        onAdd={onAdd}
                        style={defaultMentionStyle}
                    />
                </MentionsInput>

                {messageModeAction === 'edit' && (<div className="button" onClick={cancelEdit}><AiOutlineCloseCircle/></div>)}

                <div className="button button-send" style={{ backgroundColor: livechatColor }} onClick={sendMessage}>
                    <FiSend size="18"/>
                    {/* <Image width="12" src={icons.Send}/> */}
                    {/* {i18n.t('send')} */}
                </div>
            </div>
        </div>
    );
}

export default BottomBar;
