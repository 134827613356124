import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import getChannels from "../../../actions/getChannels";
import groupApi from "../../../api/group.api";
import './ModalAddOaGroup.sass';
import { Button, Tag, Space, Form, Row, Col, Modal, Select, Input, message } from 'antd';
const { TextArea } = Input;

const ModalAddOaGroup = React.memo((props) => {
    const {showModal, setShowModal} = props;
    const [channelLists, setChannelLists] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [assetId, setAssetId] = useState(null);
    const [quotaLists, setQuotaLists] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        getChannels().then(res => {
            let channels = res.data.channels;
            let oa = channels.filter(e => e.source === 'zalo');
            let channelLists = oa.map(channel => ({value: channel._id, label: channel.nameApp}));
            setChannelLists(channelLists);
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        getUsers()
    }, []);

    const getUsers = async (search = '') => {
        setLoading(true);

        try {
            // Giả sử API trả về dữ liệu như thế này
            let res = await groupApi.getUsers({search, source: 'zalo'});
            const response = res.data;
            setUsers(response);
        } catch (error) {
            console.log('Có lỗi xảy ra!');
        } finally {
            setLoading(false);
        }
    };

    const getGroupQuota = async (channelId) => {
        setLoading(true);

        try {
            // Giả sử API trả về dữ liệu như thế này
            let params = {channel_id: channelId}
            let res = await groupApi.getQuotaOaGroup(params);
            const response = res.data.data;
            const filter = response.filter(e => e.status === 'available');

            if(filter.length === 0){
                message.error('Hiện tại không có gói khả dụng, Vui lòng mua thêm gói tạo trang quản trị OA');
                return;
            }

            let quota = filter.map(e => ({
                value: e.asset_id, 
                label: `Gói: ${e.product_type} - Ngày hết hạn: ${e.valid_through}`
            }));
            setQuotaLists(quota);
        } catch (error) {
            console.log('Có lỗi xảy ra!');
        } finally {
            setLoading(false);
        }
    };

    const handleChangeChannel = (channelId) => {
        getGroupQuota(channelId);
        setSelectedChannel(channelId);
    };

    const handleChangeQuota = (assetId) => {
        setAssetId(assetId);
    };

    const handleChangeTag = (value, option) => {
        let userIds = option.map(e => e.id);
    }    

    const getOptionUsers = () => {
        let options = [];
        if(users && users.length){
            options = users.map(e => (
                {label: e.firstName, value: e.userSocialId, id: e._id, picture: e.picture}
            ));
        }

        return options;
    }

    const customTagRender = (props) => {
        const { label, value, closable, onClose } = props;

        let user = {};

        if(users && users.length){
            let findUser = users.find(e => e.userSocialId === value);
            user = findUser
        }

        return (
            <Tag
                className="tag-default-select"
                closable={closable}
                onClose={onClose}
                style={{ display: 'flex', alignItems: 'center', marginRight: 3, fontWeight: '600', border: 'none', padding: '2px 5px', marginTop: '2px', marginBottom: '2px' }}
            >
                <Picture user={user}/>{label}
            </Tag>
        );
    };

    const Picture = ({user}) => {
        let name = user.label || user.firstName
        if (user && user.picture?.location)
            return <img className="avatar-user" src={user.picture?.location} alt="avatar" />;
        else
            return <div className="avatar-user-text">{`${name.substr(0,1)}`}</div>;
    };

    const onSubmit = async (values) => {
        const res = await groupApi.createOaGroup(values);

        const result = res.data;

        if(!result.error){
            message.success('Tạo nhóm chat OA thành công!');
            setShowModal(false)
            return;
        }

        message.error(result.message);
    }

    return (
        <Modal
            open={showModal}
            id="modal-add-group"
            title="Tạo nhóm"
            onCancel={() => setShowModal(false)}
            width={800}
            footer={null}
            confirmLoading={loading}
            centered>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit} // Khi submit form
                    >
                    <Row>
                        <Col span={6}>Chọn OA tạo nhóm</Col>
                        <Col span={18}>
                            <Form.Item name="channel_id" rules={[{required: true, message: 'Bạn chưa chọn kênh OA'}]}>
                                <Select
                                    style={{ width: '400px' }}
                                    value={selectedChannel}
                                    onChange={handleChangeChannel}
                                    placeholder={`Chọn OA`}
                                    options={channelLists}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>Chọn gói</Col>
                        <Col span={18}>
                            <Form.Item name="asset_id" rules={[{required: true, message: 'Bạn chưa chọn gói nhóm OA'}]}>
                                <Select
                                    style={{ width: '400px' }}
                                    value={assetId}
                                    onChange={handleChangeQuota}
                                    placeholder={`Chọn gói`}
                                    options={quotaLists}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>Tên nhóm</Col>
                        <Col span={18}>
                            <Form.Item name="group_name" rules={[{required: true, message: 'Bạn chưa nhập tên nhóm OA'}]}>
                                <Input placeholder="Nhập tên nhóm chat"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>Mô tả nhóm</Col>
                        <Col span={18}>
                            <Form.Item name="group_description">
                                <TextArea
                                    placeholder="Nhập mô tả nhóm chat"
                                    autoSize={{
                                        minRows: 2,
                                        maxRows: 6,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>Người dùng tham gia</Col>
                        <Col span={18}>
                            <Form.Item name="member_user_ids" rules={[{required: true, message: 'Bạn chưa chọn người dùng tham gia nhóm'}]}>
                                {users && (
                                    <Select
                                        mode="multiple"
                                        className="select-users-add-group"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Chọn người dùng"
                                        onChange={handleChangeTag}
                                        options={getOptionUsers()}
                                        tagRender={customTagRender}
                                        optionRender={(option) => (
                                            <Space>
                                                <Picture user={option.data}/> {option.data.label}
                                            </Space>
                                        )}
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className='text-right'>
                        <Button type="primary" htmlType="submit">Tạo nhóm</Button>
                    </Form.Item>
                </Form>
        </Modal>
    );
});

export default ModalAddOaGroup;
