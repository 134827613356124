import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import groupApi from "../../../api/group.api";
// import './ModalAddOaGroup.sass';
import axios from 'axios';
import Config from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import getRooms from "../../../actions/getRooms";
import getRoom from "../../../actions/getRoom";
import Actions from "../../../constants/Actions";
import store from "../../../store";
import { Modal, Button, Input, Form, Row, Col, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
const { TextArea } = Input;

const ModalUpdateOaGroup = React.memo((props) => {
    const {showModal, setShowModal} = props;
    const [loading, setLoading] = useState(false);
    const room = useSelector(state => state.io.room);
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];

    const [valuesForm, setValuesForm] = useState({});

    const [imageUrl, setImageUrl] = useState("");
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        getGroupInfo();
    }, []);

    const getGroupInfo = async (id) => {
        if(!room){
            return;
        }

        let res = await groupApi.getGroupInfo({channel_id: room.channel._id, group_id: room.oa_group_id});

        let detail = res.data.data;

        const initialFileList = [
            {
                uid: "-1",
                name: "example.png",
                status: "done",
                url: detail.group_info.avatar,
            },
        ];

        form.setFieldsValue({
            thumbnail: initialFileList,
        });

        setFileList(initialFileList);
        setImageUrl(detail.group_info.avatar);

        const data = {
            group_name: detail.group_info.name,
            group_description: detail.group_info.group_description,
            avatar: detail.group_info.avatar
        }

        form.setFieldsValue(data);

        setValuesForm(data);
    }

    const reloadCurrentRoom = () => {
        getRoom(room._id).then(res => {
            let dataRoom = res.data.room;
            dispatch({type: Actions.SET_ROOM, room: dataRoom});
            dispatch({type: Actions.SET_MESSAGES, messages: dataRoom.messages});
            dispatch({type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: room._id});
        }).catch(err => console.log(err));
    }

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const onSubmit = async (values) => {
        const payload = {
            ...valuesForm,
            group_id: room.oa_group_id,
            channel_id: room.channel._id
        }

        const res = await groupApi.updateGroupInfo(payload);

        const result = res.data;

        if(!result.error){
            message.success('Cập nhật nhóm chat OA thành công!');
            setShowModal(false);

            //Reload lại room hiện tại
            reloadCurrentRoom();

            //Reload lại danh sách room
            reloadRooms();

            return;
        }

        message.error(result.message);
    }

    const beforeUpload = (file) => {
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
        if (!isImage) {
            message.error('Chỉ cho phép upload file PNG hoặc JPG!');
        }
        
        return isImage; // Chỉ trả về true nếu loại file hợp lệ
    };

    const onPreviewUpload = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleCustomRequest = async ({ file, onProgress, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append("image", file);
    
        try {
            // Gửi file qua API
            const response = await axios.post(`${Config.url}/api/upload-image`, formData, {
            headers: { "brand-code": 'sale', "api-key": "62da77474991df7aa711a632" },
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (onProgress) {
                    onProgress({ percent });
                }
            },
            });
    
            // Xử lý thành công
            onSuccess(response.data);
            let imageUrl = response.data.data.location;
            setImageUrl(imageUrl);
            console.log({imageUrl})
            setValuesForm({...valuesForm, avatar: imageUrl});
            message.success(`${file.name} đã được tải lên thành công!`);
        } catch (error) {
            // Xử lý lỗi
            onError(error);
            message.error(`${file.name} tải lên thất bại.`);
        }
    }

    const onChangeUpload = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    return (
        <Modal
            open={showModal}
            id="modal-add-group"
            title="Cập nhật nhóm"
            onCancel={() => setShowModal(false)}
            width={800}
            footer={null}
            confirmLoading={loading}
            centered>
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={(changedValues, allValues) => {
                        if(imageUrl){
                            allValues.avatar = imageUrl;
                        }
                        setValuesForm(allValues);
                    }}
                    onFinish={onSubmit} // Khi submit form
                    >
                    <Row>
                        <Col span={6}>Tên nhóm</Col>
                        <Col span={18}>
                            <Form.Item name="group_name" rules={[{required: true, message: 'Bạn chưa nhập tên nhóm OA'}]}>
                                <Input placeholder="Nhập tên nhóm chat"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>Mô tả nhóm</Col>
                        <Col span={18}>
                            <Form.Item name="group_description">
                                <TextArea
                                    placeholder="Nhập mô tả nhóm chat"
                                    autoSize={{
                                        minRows: 2,
                                        maxRows: 6,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>Ảnh đại diện</Col>
                        <Col span={18}>
                        <Form.Item 
                            name="avatar" 
                            valuePropName="fileList" 
                            getValueFromEvent={(e) => {
                                // Lấy danh sách file từ sự kiện onChange
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e?.fileList;
                            }}
                            required>
                            <ImgCrop rotationSlider>
                                <Upload
                                    action={`${Config.url}/api/upload-image`}
                                    beforeUpload={beforeUpload}
                                    customRequest={handleCustomRequest}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChangeUpload}
                                    onPreview={onPreviewUpload}
                                >
                                    {fileList.length < 1 && '+ Upload'}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className='text-right'>
                        <Button type="primary" htmlType="submit">Cập nhật</Button>
                    </Form.Item>
                </Form>
        </Modal>
    );
});

export default ModalUpdateOaGroup;
