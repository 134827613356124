import React, {useState} from 'react';
import "./BottomBarNotSend.sass";
import 'emoji-mart/css/emoji-mart.css';
import {useSelector} from "react-redux";
import ZaloMessTransModal from "./ZaloMessTransModal";
import { Button } from 'antd';

const BottomBarNotSend = () => {
    const room = useSelector(state => state.io.room);
    const [showModalZaloMessTrans, setShowModalZaloMessTrans] = useState(false);

    return (
        <>
        <div className={`bottom-bar-not-send action-event`}>
            <Button type="primary" onClick={() => setShowModalZaloMessTrans(true)}><strong>Gửi tin nhắn tương tác</strong></Button>
        </div>
        {showModalZaloMessTrans &&
            <ZaloMessTransModal 
                showModal={showModalZaloMessTrans} 
                setShowModal={setShowModalZaloMessTrans} 
            />}
        </>
    );
}

export default BottomBarNotSend;
