import React, {useContext, useEffect, useState} from 'react';
import "./MemberGroup.sass";
import {useSelector} from "react-redux";
import Picture from "../../../components/Picture";
import { AppContext } from "../../../context/AppContext";
import getMemberGroupApi from "../../../actions/getMemberGroup";
import groupApi from "../../../api/group.api";
import MemberInviteGroup from "./MemberInviteGroup"
import {FiKey, FiPlus, FiTrash2, FiCheck, FiX} from "react-icons/fi";
import { Button, message, Popconfirm } from 'antd';

const MemberGroup = () => {
    const room = useSelector(state => state.io.room);
    const { i18n } = useContext(AppContext);
    const [members, setMembers] = useState([]);
    const [pendingMembers, setPedingMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPeding, setLoadingPeding] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [offset, setOffset] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const limit = 10;

    useEffect(() => {
        if(room){
            setOffset(0);
            setShowLoadMore(false);
            setLoading(true);
            loadMemberGroup(10);
            loadPedingInviteGroup();
        }
    }, [room]);

    const loadMemberGroup = (count) => {
        groupApi.getMemberGroup(room.channel._id, room._id, 0, count).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                let listMember = result.members
                setLoading(false);
                setMembers(listMember);

                setOffset(e => e + count);
                if(listMember.length >= count) setShowLoadMore(true);
            }
            
        }).catch(err => {
            setLoading(false);
            console.log(err)
        });
    }

    const loadPedingInviteGroup = () => {
        setLoadingPeding(true)
        groupApi.getListPedingInvite({channel_id: room.channel._id, group_id: room.oa_group_id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setPedingMembers(result.members);
            }

            setLoadingPeding(false)
            
        }).catch(err => {
            console.log(err)
        });
    }

    const getMoreMemberGroup = () => {
        groupApi.getMemberGroup(room.channel._id, room._id, offset, limit).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                let listMember = result.members
                setOffset(e => e + limit);
                setMembers(e => [...e, ...listMember]);
                setLoading(false);
                listMember.length >= limit ? setShowLoadMore(true) : setShowLoadMore(false);
            }
            
        }).catch(err => {
            setLoading(false);
            console.log(err)
        });
    }

    const getAvatarMember = (member) => {
        let avatar = member.avatar;

        if (avatar){
            return <img className="picture" src={avatar} alt="Avatar User"/>;        
        }
        else
            return <div className="img">{member.name.substr(0,1).toUpperCase()}</div>;
    };

    const removeMember = async (userSocialId) => {
        const payload = {
            member_user_ids: [userSocialId],
            group_id: room.oa_group_id,
            channel_id: room.channel._id
        };

        const res = await groupApi.removeMember(payload);

        const result = res.data;

        if(!result.error){
            message.success('Xóa thành viên khỏi nhóm OA thành công!');
            setShowModal(false)
            const fitler = members.filter(e => e.userSocialId !== userSocialId)
            setMembers(fitler);
            return;
        }

        message.error(result.message);
    }

    const acceptPendingInvite = async (user_id) => {
        const payload = {
            member_user_ids: [user_id],
            group_id: room.oa_group_id,
            channel_id: room.channel._id
        };

        const res = await groupApi.acceptPendingInvite(payload);

        const result = res.data;

        if(!result.error){
            message.success('Duyệt thành viên vào nhóm OA thành công!');
            const temp = [...pendingMembers];
            const fitler = pendingMembers.filter(e => e.user_id !== user_id)
            setPedingMembers(fitler);
            const userAccept = temp.find(e => e.user_id === user_id)

            setMembers(e => [...e, ...[userAccept]]);

            return;
        }

        message.error(result.message);
    }

    const rejectPendingInvite = async (user_id) => {
        const payload = {
            member_user_ids: [user_id],
            group_id: room.oa_group_id,
            channel_id: room.channel._id
        };

        const res = await groupApi.rejectPendingInvite(payload);

        const result = res.data;

        if(!result.error){
            message.success('Đã từ chối thành viên vào nhóm!');
            const fitler = pendingMembers.filter(e => e.user_id !== user_id)
            setPedingMembers(fitler);

            return;
        }

        message.error(result.message);
    }

    return (
        <>
        <div className="social-profile">
            <div className="member-oa-group">
                <span>Danh sách thành viên ({members.length})</span>
                <Button type="primary" size="small" onClick={() => setShowModal(true)} icon={<FiPlus/>}>Mời</Button>
            </div>
            {(!loading && members.length > 0) ? (
                    members.map(member => (
                        <div key={member.user_id} className="profile-title" style={{ marginBottom: '7px' }}>
                            <div className="profile conversation">
                                {getAvatarMember(member)}
                                {member.oa_id && <FiKey className="social-icon"/>}
                            </div>
                            <div className="profile-name">
                                <div className="left">
                                    <span className="profile-name__title">{member.name}</span><br/>
                                    <span className="profile-name__sub">{member.oa_id ? 'Trưởng nhóm' : 'Thành viên'}</span>
                                </div>
                                <div className="right" style={{ display: 'inline-block' }}>
                                    {!member.oa_id && (
                                        <Popconfirm
                                            title="Xóa người dùng"
                                            description="Bạn có muốn xóa người dùng này?"
                                            onConfirm={() => removeMember(member.user_id)}
                                            okText="Đồng ý"
                                            cancelText="Hủy"
                                        >
                                            <Button type="primary" danger size="small" icon={<FiTrash2 />}></Button>
                                        </Popconfirm>
                                    )}
                                </div>
                            </div>
                        </div>))
                ) : <div className="text-center" style={{ padding: '10px', fontSize: '13px' }}>Chưa có thành viên</div>}
                {showLoadMore && (<div className="load-more" style={{ marginBottom: '7px' }} onClick={() => getMoreMemberGroup()}>{i18n.t('view_more')}</div>)}
        </div>

        <div className="social-profile">
        <div style={{ padding: '10px', fontSize: '15px', fontWeight: 600 }}>Yêu cầu tham gia nhóm ({pendingMembers.length})</div>
            {(!loadingPeding && pendingMembers.length > 0) ? (
                    pendingMembers.map(pendingMember => (
                        <div key={pendingMember.user_id} className="profile-title" style={{ marginBottom: '7px' }}>
                            <div className="profile conversation">
                                <img className="picture" src={pendingMember.avatar} alt="Avatar User"/>
                            </div>
                            <div className="profile-name">
                                <div className="left"><span className="profile-name__title">{pendingMember.name}</span></div>
                                <div className="right">
                                    <Popconfirm
                                        title="Đồng ý duyệt người dùng"
                                        description="Bạn có muốn đồng ý duyệt người dùng này?"
                                        onConfirm={() => acceptPendingInvite(pendingMember.user_id)}
                                        okText="Đồng ý"
                                        cancelText="Hủy"
                                    >
                                        <Button type="primary" size="small" style={{ marginRight: '5px' }} icon={<FiCheck style={{ cursor: 'pointer' }}/>}>Đồng ý</Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        title="Từ chối duyệt người dùng"
                                        description="Bạn có muốn từ chối duyệt người dùng này?"
                                        onConfirm={() => rejectPendingInvite(pendingMember.user_id)}
                                        okText="Đồng ý"
                                        cancelText="Hủy"
                                    >
                                        <Button type="primary" danger size="small" icon={<FiX style={{ cursor: 'pointer' }}/>}>Từ chối</Button>
                                    </Popconfirm>
                                </div>                                
                            </div>
                        </div>))
                ) : <div className="text-center" style={{ padding: '10px', fontSize: '13px' }}>Chưa có yêu cầu tham gia</div>}
        </div>
        {showModal && <MemberInviteGroup showModal={showModal} setShowModal={setShowModal}/>}
        </>
    );
}

export default MemberGroup;
