import React, { useState, useEffect, useRef } from 'react';
import {useGlobal} from "reactn";

const AudioPlayer = (props) => {
    const { audioURL, fileType, messageId} = props;
    const audioRef = useRef(null);

    useEffect(() => {
        const audioPlayMessageId = localStorage.getItem("audioPlayMessageId");

        if (audioPlayMessageId === messageId && audioRef.current) {
            audioRef.current.play()
                .then(() => {
                    localStorage.setItem("audioPlayMessageId", "");  // Lưu trạng thái
                })
                .catch((error) => console.log("Autoplay bị chặn:", error));
        }

    }, [messageId]);

    return (
        <div>
            {audioURL && (
                <audio className="voice-in-message" ref={audioRef} controls>
                    <source src={audioURL} type={`audio/${fileType}`} />
                    Trình duyệt của bạn không hỗ trợ phát audio.
                </audio>
            )}
        </div>
    );
};

export default AudioPlayer;
