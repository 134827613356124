import React, { useEffect } from 'react';
import "./sass/audio-waveform.sass"

const AudioWaveform = ({recordingTime}) => {

    useEffect(() => {
        const bar = document.querySelectorAll(".bar");
        
        bar.forEach((item) => {
            item.style.animationDuration = `${Math.random() * (0.7 - 0.2) + 0.2}s`;
        });

    }, []);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
        const secs = (seconds % 60).toString().padStart(2, '0');
        return `${mins}:${secs}`;
    };

    return (
        <div className="sound-wave-container">
            <div className="sound-wave">
                {Array.from({ length: 30 }).map((_, index) => (
                    <div key={index} className="bar"></div>
                ))}
            </div>
            <span className="audio-duration">{formatTime(recordingTime)}</span>
        </div>
    );
};

export default AudioWaveform;
