import React, { useState, useRef, useEffect } from 'react';
import {useSelector} from "react-redux";
import "./VoiceModal.sass";
import { useGlobal } from "reactn";
import Config from '../../../config';
import store from "../../../store";
import messageClient from "../../../actions/messageClient";
import Actions from "../../../constants/Actions";
import AudioWaveform from "../../../components/AudioWaveform"
import { Drawer, message } from "antd"
import axios from 'axios';
import {
    FiMic,
    FiTrash2,
    FiPlay,
    FiVolume2
} from "react-icons/fi";

const VoiceModal = (props) => {
    const { openVoice, setOpenVoice } = props;
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioURL, setAudioURL] = useState(null);
    const [audioStream, setAudioStream] = useGlobal('audioStream');

    const [recordingTime, setRecordingTime] = useState(0);
    const intervalRef = useRef(null);
    const isListeningRef = useRef(true);

    const room = useSelector(state => state.io.room);
    const user = useGlobal('user')[0];
    const chatMode = useGlobal('chatMode')[0];

    const handleStartRecording = async() => {
        try {
            let newStream = audioStream;

            if (!newStream) {
                newStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setAudioStream(newStream);
            }

            const recorder = new MediaRecorder(newStream);
            const chunks = [];

            recorder.ondataavailable = (event) => {
                chunks.push(event.data);
            };

            recorder.onstop = async () => {
                const blob = new Blob(chunks, {type: 'audio/webm'});
                setAudioBlob(blob);
                setAudioURL(URL.createObjectURL(blob)); // Tạo URL tạm thời để phát audio
                if (isListeningRef.current) {
                    console.log("Đã dừng ghi âm để nghe lại.");
                    // Chỉ cần phát lại âm thanh mà không gửi đi
                } else {
                    console.log("Đã dừng ghi âm và chuẩn bị gửi.");
                    sendMessageAudio(blob); // Gửi audio khi không phải nghe lại
                }
            };

            setRecordingTime(0);
            if (intervalRef.current) clearInterval(intervalRef.current);

            intervalRef.current = setInterval(() => {
                setRecordingTime(prev => prev + 1);
            }, 1000);

            recorder.start();
            setMediaRecorder(recorder);
            setRecording(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
            message.warn('Không thể truy cập microphone. Hãy cấp quyền microphone cho trang web.');
        }
    };

    //Gửi ngay khi ghi âm
    const handleSendNowRecording = () => {
        isListeningRef.current = false;
        setOpenVoice(false);
        setAudioURL(null)
        
        if(mediaRecorder) {
            mediaRecorder.stop();
            setRecording(false);
            clearInterval(intervalRef.current);
        }
    };

    //Gửi sau khi nghe lại
    const handleSendRecording = () => {
        setOpenVoice(false);
        sendMessageAudio(audioBlob);
        setAudioURL(null)
    };

    //Nghe lại audio
    const handleReviewRecording = () => {
        isListeningRef.current = true

        if (mediaRecorder) {
            mediaRecorder.stop();
        }

        clearInterval(intervalRef.current);
    };

    //Xóa audio
    const handleDeleteRecording = () => {
        isListeningRef.current = false
        setRecording(false);
        setMediaRecorder(null);
        setAudioURL(null);
        clearInterval(intervalRef.current);
    };

    //Upload file tin nhắn thoại
    const handleUpload = async (blob) => {
        if(!blob) return;

        const url = (Config.url || '') + '/api/upload/file';
        
        const data = new FormData();
    
        data.append('file', blob, 'voice_message.webm');
    
        const response = await axios.post(url, data);

        let result = response.data

        return result?.file;
    };

    //Gửi tin nhắn thoại
    const sendMessageAudio = async (dataBlob) => {
        const audio = await handleUpload(dataBlob);

        if(!audio){
            message.error('Gửi đoạn ghi âm thất bại!')
            return;
        }

        let resMess;
        let messageData = {
            roomID: room._id, 
            authorID: user.id, 
            content: '', 
            type: 'audio', 
            fileID: audio._id,
            audioUrl: audio.location
        }

        if(chatMode === 'client'){
            resMess = await messageClient(messageData);
        }
        else{
            resMess = await message(messageData);
        }

        resMess = resMess?.data;

        let newMessage = resMess?.data?.message;
        
        if(resMess.error){
            newMessage = resMess.data.message;
            newMessage.error_message = resMess.message;
        }

        store.dispatch({type: Actions.MESSAGE, message: newMessage});
    }

    const onClose = () => {
        setOpenVoice(false);
    };

    return ( 
        <Drawer title = "Tin nhắn thoại"
            placement="bottom"
            className="voice-recording-wrapper"
            closable={ true }
            onClose={ onClose }
            open={ openVoice }
            height={300}
            key="bottom" >
            {audioURL && (
                <div>
                    <audio controls style={{ marginBottom: '30px' }}>
                        <source src={audioURL} type="audio/webm" />
                        Trình duyệt của bạn không hỗ trợ phát audio.
                    </audio>
                </div>
            )}
            <div className = "audio-record"> 
                {!recording ? ( <div className = "before-record" style={{ marginTop: '30px' }}>
                    <p> Bấm vào nút bên dưới để bắt đầu ghi âm </p> 
                    <div className="btn-record" style={{ marginTop: '15px' }} onClick = {handleStartRecording}> <FiMic size = "25" color="white" /></div> </div>
                ) : ( 
                <div className="after-record" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    {!audioURL && (<AudioWaveform recordingTime={recordingTime}/>)}
                    <div style={{ display: 'flex'}}>
                    <div className="after-record__item">
                        <div className="btn-record default" style={{ margin: '0 25px' }} onClick = {handleDeleteRecording}> <FiTrash2 size = "25" color = "white"/></div>
                        <p style={{ marginTop: '5px' }}>Xóa</p>
                    </div> 
                    {audioURL ? 
                    (
                        <div className="after-record__item">
                            <div className={`btn-record ${!audioURL ? 'recording' : ''} `} style={{ margin: '0 25px' }} onClick={handleSendRecording}> <FiPlay size="25" color="white"/></div>
                            <p style={{ marginTop: '5px' }}>Gửi</p>
                        </div>
                    ) : (
                        <>
                        <div className="after-record__item">
                            <div className={`btn-record ${!audioURL ? 'recording' : ''} `} style={{ margin: '0 25px' }} onClick={handleSendNowRecording}> <FiPlay size="25" color="white"/></div>
                            <p style={{ marginTop: '5px' }}>Gửi</p>
                        </div>
                        <div className="after-record__item">
                            <div className="btn-record default" style={{ margin: '0 25px' }} onClick={handleReviewRecording}> <FiVolume2 size="25" color="white"/></div>
                            <p style={{ marginTop: '5px' }}>Nghe lại</p>
                        </div>
                        </>
                    )}
                    </div>
                </div>
                )
            }
            </div> 
        </Drawer>
    );
}

export default VoiceModal;