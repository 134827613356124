import axios from "axios";
import Config from "../config";

const groupApi = {
    getMemberGroup: (channel_id, room_id, offset = 0, limit = 10) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/get-members",
            data: {channel_id, room_id, offset, limit}
        });
    },

    getGroupInfo: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/get-info",
            data: params
        });
    },

    updateGroupInfo: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/update-info",
            data: params
        });
    },

    getListPedingInvite: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/list-pending-invite",
            data: params
        });
    },

    acceptPendingInvite: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/accept-pending-invite",
            data: params
        });
    },

    rejectPendingInvite: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/reject-pending-invite",
            data: params
        });
    },

    getQuotaOaGroup: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/get-oa-quota",
            data: params
        });
    },

    createOaGroup: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/create-oa-group",
            data: params
        });
    },

    inviteMember: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/invite-member",
            data: params
        });
    },

    removeMember: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/group/remove-member",
            data: params
        });
    },

    getUsers: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/user/list",
            data: params
        });
    },
};

export default groupApi;